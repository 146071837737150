/* Orders.css */
.action-btn { 
  padding: 0.2rem 2rem !important;
}

.table-custom th,
.table-custom td {
  vertical-align: middle;
}

/* Limit the width for Order ID columns and allow text wrapping */
.order-id-header,
.order-id {
  max-width: 150px;
  word-break: break-all;
}


/* Styling for the search input */
.search-input {
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  flex: 1;
}

/* Utility margin for buttons */
.ml-2 {
  margin-left: 10px;
}

/* Optional styling for modal buttons */
.modal-btn {
  width: 80px;
  font-size: 17px;
}

.location-btn {
  width: 200px; /* Set a fixed width for the buttons */
  height: 50px; /* Set a fixed height for the buttons */
  font-size: 1.5rem; /* Base font size */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px; /* Standard margin */
  padding: 10px; /* Padding to ensure proper spacing */
  border-radius: 4px; /* Rounded corners */
}